<template>
  <Modal v-model="resDetailModal" title="组合资源清单明细" width="1000" footer-hide>
    <Table size="small" stripe :data="list" :columns="tableColumns"></Table>
  </Modal>
</template>

<script>
import { getComResource } from '@/api/product/resource'

export default {
  data () {
    return {
      resDetailModal: false,

      list: [],
      tableColumns: [
        { title: '编号', align: 'center', key: 'code' },
        { title: '站点', align: 'center', key: 'stationName' },
        { title: '类型', align: 'center', key: 'resourcetypeName' },
        { title: '等级', align: 'center', key: 'levelName' },
        { title: '楼层', align: 'center', key: 'floor' },
        { title: '面积(㎡)', align: 'center', key: 'area' },
        {
          title: '制作尺寸(mm)',
          align: 'center',
          render: (h, params) => {
            return h('span', `${params.row.makewidth} * ${params.row.makeheight}`)
          }
        },
        {
          title: '见光尺寸(mm)',
          align: 'center',
          render: (h, params) => {
            return h('span', `${params.row.finalwidth} * ${params.row.finalheight}`)
          }
        }
      ]
    }
  },
  methods: {
    showModal (comId) {
      this.resDetailModal = true

      getComResource({ comId }).then(res => {
        this.list = res
      })
    }
  }
}
</script>
