<template>
  <div>
    <Row :gutter="6" class="m-b-5">
      <i-col :xs="24" :sm="12" :md="4" :lg="2" class="m-b-5">
        <i-select size="small" v-model="assetId" @on-change="handleChangeAsset">
          <Option :value="-1">全部资产</Option>
          <i-option :value="item.id" v-for="item in assetArray" :key="item.id">{{ item.name }}</i-option>
        </i-select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="2" class="m-b-5">
        <i-select size="small" placeholder="所属项目" clearable v-model="query.projectId" @on-change="getPackageData">
          <i-option v-for="item in projectArray" :key="'project_' + item.id" :value="item.id">{{ item.name
            }}</i-option>
        </i-select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="2" class="m-b-5">
        <i-select size="small" placeholder="所属资产包" clearable v-model="query.packageId" :disabled="!query.projectId">
          <i-option v-for="item in packageArray" :key="'package_' + item.id" :value="item.id">{{ item.name
            }}</i-option>
        </i-select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="2" class="m-b-5">
        <i-select size="small" placeholder="站点（可通过关键字搜索）" clearable filterable v-model="query.stationId">
          <i-option :value="item.id" v-for="item in stationArray" :key="item.id">{{ assetId === -1 ? `${item.name} -
            ${item.assetName}` : item.name }}</i-option>
        </i-select>
      </i-col>
      <!-- <i-col
        :xs="24"
        :sm="12"
        :md="6"
        :lg="3"
        class="m-b-5"
      >
        <i-select
          size="small"
          placeholder="位置"
          clearable
          v-model="query.position"
        >
          <i-option
            v-for="item in positionArray"
            :value="item.value"
            :key="'pos_'+item.value"
          >{{item.name}}</i-option>
        </i-select>
      </i-col> -->
      <i-col :xs="24" :sm="12" :md="6" :lg="3" class="m-b-5">
        <i-select size="small" placeholder="资源类型（可通过关键字搜索）" clearable filterable v-model="query.resourcetypeId">
          <OptionGroup v-for="item in resourceTypeArray" :key="'re_parent_' + item.id" :label="item.name">
            <Option v-for="child in item.children" :value="child.id" :key="'re_child' + child.id">{{ child.name }}
            </Option>
          </OptionGroup>
        </i-select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="2" class="m-b-5">
        <i-select size="small" placeholder="状态" clearable v-model="query.enabled">
          <i-option v-for="item in statusArray" :key="'status_' + item.value" :value="item.value">{{
            item.name }}</i-option>
        </i-select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" :lg="3" class="m-b-5">
        <i-select size="small" placeholder="开放市场" clearable v-model="query.open">
          <i-option v-for="item in openArray" :key="'open_' + item.value" :value="item.value">{{ item.name }}</i-option>
        </i-select>
      </i-col>

      <i-col :xs="24" :sm="12" :md="6" :lg="3" class="m-b-5">
        <i-input size="small" placeholder="关键字：支持名称查询" v-model="query.keyword"></i-input>
      </i-col>
      <i-col :xs="24" :sm="12" :md="5" :lg="5" class="m-b-5">
        <i-button size="small" class="m-r-5" type="primary" icon="ios-search" @click="handleSearch()">搜索</i-button>
        <Button size="small" class="m-r-5" type="primary" @click="handleAdd">新增资源</Button>
        <Button size="small" type="success" class="m-r-5" @click="onSetOpenMarket">批量设置开放市场</Button>
      </i-col>
    </Row>

    <Row>
      <i-col :xs="24" :sm="12" :md="19" :lg="19">
        <!-- <RadioGroup v-model="selectedResourceType" type="button" button-style="solid" @on-change="handleSearch">
            <Radio :label="1">常规资源</Radio>
            <Radio :label="2">组合资源</Radio>
        </RadioGroup> -->
      </i-col>
      <i-col :xs="24" :sm="12" :md="5" :lg="5" class="m-b-5">
        <Button size="small" type="success" class="m-r-5 m-l-5" @click="handleImport">资源导入</Button>
        <Button size="small" type="success" class="m-r-5" @click="handleDownload">资源导出</Button>
      </i-col>
    </Row>

    <div>
      <Table size="small" stripe :data="list" :columns="showTableColumns"></Table>
      <div class="paging_style">
        <Page size="small" :total="total" :page-size="query.pageSize" show-total show-elevator
          :current="query.pageNumber" @on-change="handlePageChange"></Page>
      </div>
    </div>

    <Modal v-model="resAddModal" width="600">
      <p slot="header" class="text-center">{{ operate === 1 ? '新增' : '编辑' }}资源</p>
      <medium-add v-if="resAddModal" ref="resAdd" :operate="operate" :assetArray="assetArray"
        :resourceTypeArray="resourceTypeArray" :onSuccess="initData"></medium-add>
      <div slot="footer">
        <Button type="text" class="m-r-5" @click="resAddModal = false">取消</Button>
        <Button type="primary" @click="handleAddRes">确定</Button>
      </div>
    </Modal>

    <set-open-market ref="setOpenMarket" :assetArray="assetArray" :onSuccess="initData" />
    <import-device ref="importDevice" :source="2" :onSuccess="initData"></import-device>
    <export-resource ref="exportResource" :assetArray="assetArray"></export-resource>
    <medium-com-detail ref="mediumComDetail"></medium-com-detail>

  </div>
</template>

<script>
import MediumAdd from './MediumAdd.vue'
import MediumComDetail from './MediumComDetail.vue'
import SetOpenMarket from './SetOpenMarket.vue'
import ImportDevice from './ImportDevice.vue'
import ExportResource from '../common/ExportResource.vue'
import { deviceResMixins } from '../mixins/index'
import { getResourcePage, freezeResource, getPackageList, getComPage } from '@/api/product/resource'
// import { getStationByAsset } from '@/api/product/station'
/** new api */
// import { getRimStationByAssetIds } from '@/api/rim/station'
import { getPublisherProductAssetList, getResourceTypeList } from '@/api/product/asset'

// import { getPositionList } from '@/api/rim/asset'

export default {
  props: {
    projectArray: [Array]
  },
  mixins: [deviceResMixins],
  components: {
    MediumAdd,
    MediumComDetail,
    SetOpenMarket,
    ImportDevice,
    ExportResource
  },
  data () {
    return {
      resAddModal: false,
      resDetailModal: false,
      selectedResourceType: 1,
      operate: 1, // 1：新增，2：编辑
      assetId: null,
      query: {
        pageNumber: 1,
        pageSize: 15,
        assetId: null,
        // cityId: null,
        enabled: 1,
        fault: null,
        level: null,
        position: null,
        resource: null,
        resourcetypeId: null,
        stationId: null,
        keyword: '',
        status: null,
        deleted: undefined,
        open: undefined,
        projectId: undefined,
        packageId: undefined
      },
      queryDeleted: undefined,
      list: [],
      total: 0,
      assetArray: [],
      // stationArray: [],
      positionArray: [],
      resourceTypeArray: [],
      packageArray: [],
      statusArray: [
        { value: 1, name: '可用' },
        { value: 0, name: '不可用' },
        { value: -1, name: '冻结' }
      ],
      showTableColumns: [],

      tableColumns: [
        { title: '编号', align: 'center', key: 'code' },
        { title: '站点', align: 'center', key: 'stationName' },
        { title: '类型', align: 'center', key: 'resourcetypeName' },
        { title: '所属资产包', align: 'center', tooltip: true, key: 'packageName' },
        { title: '等级', align: 'center', key: 'levelName' },
        { title: '楼层', align: 'center', key: 'floor' },
        { title: '面积(㎡)', align: 'center', key: 'area' },
        {
          title: '制作尺寸(mm)',
          align: 'center',
          render: (h, params) => {
            return h('span', `${params.row.makewidth} * ${params.row.makeheight}`)
          }
        },
        {
          title: '见光尺寸(mm)',
          align: 'center',
          render: (h, params) => {
            return h('span', `${params.row.finalwidth} * ${params.row.finalheight}`)
          }
        },
        // {
        //   title: '材质/工艺',
        //   align: 'center',
        //   render: (h, params) => {

        //   }
        // },
        {
          title: '状态',
          align: 'center',
          key: 'enabled',
          render: (h, params) => {
            const status = this.statusArray.find(x => x.value === params.row.enabled)
            return h('span', status ? status.name : '/')
          }
        },
        {
          title: '开放市场',
          align: 'center',
          key: 'open',
          render: (h, { row }) => {
            const open = this.openArray.find(x => x.value === row.open)
            let color = 'default'
            switch (row.open) {
              case 0:
                color = 'default'
                break
              case 1:
                color = 'primary'
                break
              case 2:
                color = 'warning'
                break
            }
            return h('Tag', {
              props: {
                color: color
              }
            }, open ? open.name : '/')
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.operate = 2
                    this.$store.commit('set_cur_res_info', params.row)
                    console.log(params.row)
                    this.resAddModal = true
                  }
                }
              }, '编辑'),
              h('a', {
                style: { color: params.row.enabled !== -1 ? '#ef4f4f' : '' },
                on: {
                  click: () => {
                    this.onFreezeRes(params.row)
                  }
                }
              }, params.row.enabled === -1 ? '解冻' : '冻结')
            ])
          }
        }
      ],
      tableColumns2: [
        { title: '编号', align: 'center', key: 'code' },
        { title: '站点', align: 'center', key: 'stationName' },
        { title: '类型', align: 'center', key: 'resourcetypeName' },
        { title: '所属资产包', align: 'center', tooltip: true, key: 'packageName' },
        {
          title: '状态',
          align: 'center',
          key: 'enabled',
          render: (h, params) => {
            const status = this.statusArray.find(x => x.value === params.row.enabled)
            return h('span', status ? status.name : '/')
          }
        },
        {
          title: '开放市场',
          align: 'center',
          key: 'open',
          render: (h, { row }) => {
            const open = this.openArray.find(x => x.value === row.open)
            let color = 'default'
            switch (row.open) {
              case 0:
                color = 'default'
                break
              case 1:
                color = 'primary'
                break
              case 2:
                color = 'warning'
                break
            }
            return h('Tag', {
              props: {
                color: color
              }
            }, open ? open.name : '/')
          }
        },
        {
          title: '包含资产',
          align: 'center',
          render: (h, params) => {
            return h('a', {
              on: {
                click: () => {
                  this.$refs.mediumComDetail.showModal(params.row.id)
                }
              }
            }, '查看资源明细')
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.operate = 2
                    this.$store.commit('set_cur_res_info', params.row)
                    console.log(params.row)
                    this.resAddModal = true
                  }
                }
              }, '编辑'),
              h('a', {
                style: { color: params.row.enabled !== -1 ? '#ef4f4f' : '' },
                on: {
                  click: () => {
                    this.onFreezeRes(params.row)
                  }
                }
              }, params.row.enabled === -1 ? '解冻' : '冻结')
            ])
          }
        }
      ]
    }
  },
  mounted () {
    this.getPublisherAssetData()
    // this.getPositionData()
  },
  methods: {
    initData () {
      this.query.deleted = this.queryDeleted !== undefined ? Boolean(this.queryDeleted) : undefined

      if (this.selectedResourceType === 1) {
        this.showTableColumns = this.tableColumns

        getResourcePage(this.query).then(res => {
          if (res && !res.errcode) {
            this.list = res.list
            this.total = res.totalRow
            this.query.pageNumber = res.pageNumber
          } else {
            this.list = []
            this.total = 0
            this.query.pageNumber = 1
          }
        })
      } else {
        this.showTableColumns = this.tableColumns2

        getComPage(this.query).then(res => {
          if (res && !res.errcode) {
            this.list = res.list
            this.total = res.totalRow
            this.query.pageNumber = res.pageNumber
          } else {
            this.list = []
            this.total = 0
            this.query.pageNumber = 1
          }
        })
      }
    },
    getPublisherAssetData () {
      getPublisherProductAssetList().then(res => {
        if (res && !res.errcode) {
          this.assetArray = res
          this.assetId = this.assetArray[0].id
          this.query.assetId = this.assetId === -1 ? undefined : this.assetId
          this.$store.commit('set_selected_assetId', this.assetId)
        } else {
          this.assetArray = []
          this.assetId = undefined
          this.query.assetId = this.assetId
        }
        this.initStationArray()
        this.getResourceTypeData()
        this.initData()
      })
    },
    // 初始化站点选项
    initStationArray () {
      this.query.stationId = null
      let assetIds = []
      if (this.assetId <= -1) {
        this.assetArray.forEach(item => {
          assetIds = assetIds.concat(this.assemblyRimAssetIds(item.rimAssetIds))
        })
      } else {
        const rimAsset = this.assetArray.find(x => x.id === this.assetId)
        if (rimAsset) {
          assetIds = this.assemblyRimAssetIds(rimAsset.rimAssetIds)
        }
      }
      // 调用mixins中的方法
      this.getStationData(assetIds)
      // const assetIds = this.assetId === -1 ? this.assetArray.map(x => x.id) : [this.assetId]
      // getRimStationByAssetIds({ assetIds: JSON.stringify(assetIds) }).then((res) => {
      //   this.stationArray = res
      //   this.$store.commit('set_station_array', this.stationArray)
      // })
    },

    // getPositionData () {
    //   const assetIds = this.assetId === -1 ? this.assetArray.map(x => x.id) : [this.assetId]
    //   getPositions({ assetIds: JSON.stringify(assetIds) }).then(res => {
    //     if (res && !res.errcode) {
    //       this.positionArray = res
    //     } else {
    //       this.positionArray = []
    //     }
    //   })
    // },
    /**
    * 获取资源类型数据
    */
    getResourceTypeData () {
      const assetIds = this.assetId === -1 ? this.assetArray.map(x => x.id) : [this.assetId]
      getResourceTypeList({ assetIds: JSON.stringify(assetIds) }).then(res => {
        if (res && !res.errcode) {
          this.resourceTypeArray = res
        } else {
          this.resourceTypeArray = []
        }
      })
    },
    handleSearch () {
      this.query.pageNumber = 1
      this.initData()
    },
    handleChangeAsset () {
      this.query.assetId = this.assetId === -1 ? undefined : this.assetId
      this.$store.commit('set_selected_assetId', this.assetId)
      this.initStationArray()
      // this.getPositionData()
      this.getResourceTypeData()
      this.handleSearch()
    },
    handlePageChange (curPage) {
      this.query.pageNumber = curPage
      this.initData()
    },
    handleAdd () {
      this.operate = 1
      this.resAddModal = true
    },
    handleAddRes () {
      this.$refs.resAdd.handleSubmit()
      this.resAddModal = false
    },
    onSetOpenMarket () {
      this.$nextTick(() => {
        this.$refs.setOpenMarket.showModal()
      })
    },
    onFreezeRes (params) {
      this.$Modal.confirm({
        title: '操作提示',
        content: `确定要${params.enabled === -1 ? '解冻' : '冻结'}该资源`,
        onOk: () => {
          freezeResource({ resourceId: params.resourceId, freeze: params.enabled !== -1 }).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.initData()
            }
          })
        }
      })
    },
    handleImport () {
      this.$refs.importDevice.show()
    },
    async getPackageData () {
      if (this.query.projectId) {
        const result = await getPackageList({ projectId: this.query.projectId })
        this.packageArray = (result && !result.errcode) ? result : []
      } else {
        this.packageArray = []
      }
    },
    handleDownload () {
      this.$refs.exportResource.handleDownload()
    }
  }
}
</script>
